@font-face {
    font-family: 'Aeonikmono';
    src: url('https://assets.website-files.com/5f49c40736fbe713860f9203/62acf5d9457d975465c6a099_AeonikMono-Regular.woff2') format('woff2'), url('https://assets.website-files.com/5f49c40736fbe713860f9203/62acf5d92c2bd4d356af1181_AeonikMono-Regular.woff') format('woff'), url('https://assets.website-files.com/5f49c40736fbe713860f9203/62ac91fbeebc24a8d299d482_AeonikMono-Regular.ttf') format('truetype'), url('https://assets.website-files.com/5f49c40736fbe713860f9203/62aba2036517db99c36a26d5_AeonikMono-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('https://assets.website-files.com/5f49c40736fbe713860f9203/5f49cfb07c2fee5f311b6b66_Aeonik-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Aeonik';
    src: url('https://assets.website-files.com/5f49c40736fbe713860f9203/5f49cfb0cfe39c60b90e9999_Aeonik-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Aeonik';
    src: url('https://assets.website-files.com/5f49c40736fbe713860f9203/5f49cfb0a5fd5b3412a681bc_Aeonik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Aeonik';
    src: url('https://assets.website-files.com/5f49c40736fbe713860f9203/61f4636c5d0dd98dd29a2c29_Aeonik-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('https://assets.website-files.com/5f49c40736fbe713860f9203/61f4636c55c90484f87e63dd_Aeonik-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonikmono';
    src: url('https://assets.website-files.com/5f49c40736fbe713860f9203/62ac91fbead36995d984300f_AeonikMono-Bold.ttf') format('truetype'), url('https://assets.website-files.com/5f49c40736fbe713860f9203/62aba2035316175caff12efa_AeonikMono-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonikmono';
    src: url('https://assets.website-files.com/5f49c40736fbe713860f9203/62ac91fb87101a86f5ef0bd5_AeonikMono-Thin.ttf') format('truetype'), url('https://assets.website-files.com/5f49c40736fbe713860f9203/62aba2477144683996ff31b9_AeonikMono-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonikmono';
    src: url('https://assets.website-files.com/5f49c40736fbe713860f9203/62ac91fb57b85977aee7be08_AeonikMono-Light.ttf') format('truetype'), url('https://assets.website-files.com/5f49c40736fbe713860f9203/62aba203107da364f2e48e9f_AeonikMono-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonikmono';
    src: url('https://assets.website-files.com/5f49c40736fbe713860f9203/62ac91fbeebc24788e99d483_AeonikMono-Medium.ttf') format('truetype'), url('https://assets.website-files.com/5f49c40736fbe713860f9203/62aba2470d10977cc23effb8_AeonikMono-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}